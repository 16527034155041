import { Injectable } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class AppGlobals {
  public readonly API_HOST = environment.apiHost;
  public readonly appVersion = '0.1.1';
  public readonly restPrefix = `${this.API_HOST}/integra-rainbow-api`;

  public getAppVersion = () => this.appVersion;
  public getRestPrefix = () => this.restPrefix;

  public idFila: number;
  public isInFullScreen = false;
  public pixelRatio: number = 0;
  public alunoInfo: any;
  public url2ndCam: SafeResourceUrl;
  public firstConnectionDone: boolean = false;
  public provaEmAndamento: boolean = false;
  public provaInterrompida: boolean = false;
  public provaEncerrada: boolean = false;
  public inConference: boolean = false;
  public hasConferenceToJoin: boolean = false;
  public bubbleCalling: any;
  public videoDeviceId: any;
  public videoDevices: any = [];
  public advertencias: number = 0;
  public toleranciaTempoProva = 0;
  public tempoEsperaParaAdicionarToleranciaEmMs = 0;
  public mediaStream: MediaStream;
  public posicaoNaFila: number;
  public connectedUserRainbowContact: any;
  public wcamPublisherContactId: string;
}
