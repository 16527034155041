import { Component } from '@angular/core';
import {AppGlobals} from "./app.globals";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Uniasselvi - Prova online supervisionada';

  constructor(public globals: AppGlobals ) {
    this.globals.pixelRatio =  parseFloat( window.devicePixelRatio.toString() );
    console.log('conf: PixelRatio ', this.globals.pixelRatio );
  }
}
